export default {

  design: '製品デザイン',
  outlook: '製品の外观',
  spec: `製品仕様`,

  product: {


    acces: [
      { text: "accesscontroller" },
      { text: "accessauto" },
      { text: "cloud" },
    ],

    accesscontroller: {
      title: 'Access Controller',
      spec: [
        {
          name: '外観と素材',
          detail: [
            {
              title: '素材',
              description: 'ABS/White, ABS/Gray, ABS/Black, PC/Translucent',
            },
            {
              title: '寸法',
              description: '85.4mm x 85.4mm x 24.5mm (幅 x 高さ x 厚さ)',
            },
          ],
        },
        {
          name: 'プロセッサとメモリ',
          detail: [
            {
              title: 'プロセッサ',
              description: 'Cortex M4 64MHz',
            },
            {
              title: 'メモリ',
              description: '512KB、64KB',
            },
          ],
        },
        {
          name: 'ネットワーク',
          detail: [
            {
              title: '接続性',
              description: 'BLE 5.2',
            },
          ],
        },
        {
          name: 'インターフェース',
          detail: [
            {
              title: 'アラーム',
              description: 'RGB LED x 4',
            },
          ],
        },
        {
          name: '使用環境と電源',
          detail: [
            {
              title: '動作条件',
              description: '-10°C 〜 +60°C、0% 〜 90%',
            },
            {
              title: '電源',
              description: 'CR2477 1S 4P',
            },
          ],
        },
      ],
    },
    accessauto: {
      title: 'Access Auto',
      spec: [
        {
          name: '外観と素材',
          detail: [
            {
              title: '素材',
              description: 'ABS/White、PC/Translucent Black',
            },
            {
              title: '寸法',
              description: '210.1mm x 60.1mm x 42.2mm (幅 x 高さ x 厚さ)',
            },
          ],
        },
        {
          name: 'プロセッサとメモリ',
          detail: [
            {
              title: 'プロセッサ',
              description: 'Dual-core 32bit LX6, 240MHz',
            },
            {
              title: 'メモリ',
              description: 'FLASH 8MB, PSRAM 2MB',
            },
          ],
        },
        {
          name: 'ネットワーク',
          detail: [
            {
              title: '接続性',
              description: 'BLE 5.2, Wi-Fi 802.11b/g/n, 24GHz Microwave, SUB 1GHz',
            },
          ],
        },
        {
          name: 'インターフェース',
          detail: [
            {
              title: 'アラーム',
              description: 'Door control, Shock detection, BEEP',
            },
            {
              title: 'シリアル',
              description: 'RS232(USB Console)',
            },
          ],
        },
        {
          name: '使用環境と電源',
          detail: [
            {
              title: '動作条件',
              description: '0°C 〜 +50°C、0% 〜 90%',
            },
            {
              title: '電源',
              description: 'DC 12V, 24V (Non Polarity)',
            },
          ],
        },
      ],
    },
    cloud: {
      title: 'Access Controller',
      spec: [
        {
          name: '外観と素材',
          detail: [
            {
              title: '素材',
              description: 'ABS/White、PC/Translucent Black',
            },
            {
              title: '寸法',
              description: '210.1mm x 60.1mm x 42.2mm (幅 x 高さ x 厚さ)',
            },
          ],
        },
        {
          name: 'プロセッサとメモリ',
          detail: [
            {
              title: 'プロセッサ',
              description: 'Dual-core 32bit LX6, 240MHz',
            },
            {
              title: 'メモリ',
              description: 'FLASH 8MB, PSRAM 2MB',
            },
          ],
        },
        {
          name: 'ネットワーク',
          detail: [
            {
              title: '接続性',
              description: 'BLE 5.2, Wi-Fi 802.11b/g/n, 24GHz Microwave, SUB 1GHz',
            },
          ],
        },
        {
          name: 'インターフェース',
          detail: [
            {
              title: 'アラーム',
              description: 'Door control, Shock detection, BEEP',
            },
            {
              title: 'シリアル',
              description: 'RS232(USB Console)',
            },
          ],
        },
        {
          name: '使用環境と電源',
          detail: [
            {
              title: '動作条件',
              description: '0°C 〜 +50°C、0% 〜 90%',
            },
            {
              title: '電源',
              description: 'DC 12V, 24V (Non Polarity)',
            },
          ],
        },
      ],
    },
    serverpcRTLS: {
      title: 'サーバーパソコン',
      spec: [
        {
          name: 'プロセッサーとメモリ',
          detail: [
            {
              title: 'プロセッサー',
              description: 'Intel Xeon E-2334（3.4GHz 4コア 8M L3 65W）',
            },
            {
              title: 'メモリ',
              description: '16GB 3200MT/s DDR4 ECC UDIMM、最大64GB、4つのスロット',
            },
            {
              title: 'ストレージ',
              description: '1TB 7,200RPM SATA 3.5',
            },
          ],
        },
        {
          name: 'ネットワーク',
          detail: [
            {
              title: 'ネットワークコントローラー',
              description: 'オンボードBroadcom 5720デュアルポート1Gb LOM',
            },
          ],
        },
        {
          name: '使用環境と電源',
          detail: [
            {
              title: '重量',
              description: '12.2kg',
            },
            {
              title: '拡張スロット',
              description: '2つのPCIeスロット',
            },
            {
              title: '電源',
              description: 'デュアル、ホットプラグ、冗長電源（1+1）、600W',
            },
          ],
        },
      ],
    },

    serverpc: {
      title: 'Server PC',
      spec: [
        {
          name: 'プロセッサとメモリ',
          detail: [
            {
              title: 'プロセッサ',
              description: 'Intel Xeon silver 4210R, 1CPU, 10 Core',
            },
            {
              title: 'メモリ',
              description: '32GB',
            },
            {
              title: '保存容量',
              description: '1TB + 4TB',
            },
          ],
        },
        {
          name: '使用環境と電源',
          detail: [
            {
              title: '重量',
              description: '17.6kg',
            },
            {
              title: '拡張スロット',
              description: '16 PCI Gen3スロット x 2',
            },
            {
              title: '電源',
              description: '450W',
            },
          ],
        },
      ],
    },
    accesspro: {
      title: 'Access Pro',
      spec: [
        {
          name: '外観と素材',
          detail: [
            {
              title: '素材',
              description: 'Aluminum/Gray, PC/Translucent',
            },
            {
              title: '寸法',
              description: '256mm x 273mm x 24mm (幅 x 高さ x 厚さ)',
            },
            {
              title: '重量',
              description: '1.2kg',
            },
          ],
        },
        {
          name: 'プロセッサとメモリ',
          detail: [
            {
              title: 'プロセッサ',
              description: 'Quad core-A17 1.8Ghz',
            },
            {
              title: 'メモリ',
              description: '2GB / 16GB',
            },
          ],
        },
        {
          name: 'センサーとカメラ',
          detail: [
            {
              title: 'イメージセンサー',
              description: '1/3.06" CMO, MN34229',
            },
            {
              title: 'レンズタイプ',
              description: 'Fixed-focal',
            },
            {
              title: 'カメラの視野角',
              description: '180º',
            },
            {
              title: '解像度',
              description: '1920 x 1080',
            },
          ],
        },
        {
          name: 'ディスプレイ',
          detail: [
            {
              title: '解像度',
              description: '10.1" TFT LCD 1280x800',
            },
          ],
        },
        {
          name: 'インターフェース',
          detail: [
            {
              title: 'オーディオ',
              description: 'MIC, Speaker',
            },
            {
              title: '通知',
              description: 'Door Control, Emergency Call',
            },
            {
              title: 'USB',
              description: 'USB2.0 Host x2, USB2.0 OTG x 1',
            },
            {
              title: 'シリアル',
              description: 'RS232(USBコンソール)',
            },
          ],
        },
        {
          name: '使用環境と電源',
          detail: [
            {
              title: '動作条件',
              description: '0°C 〜 +40°C、0% 〜 90%',
            },
            {
              title: '電源',
              description: 'ADAPTOR 12V / 3A',
            },
          ],
        },
      ],
    },


    accesskey: {
      title: 'Access Key',
      spec: [
        {
          name: '外観と素材',
          detail: [
            {
              title: '素材',
              description: 'Aluminum/Gray, PC/Translucent, ABS/White',
            },
            {
              title: '寸法',
              description: '49mm x 26mm x 9mm (幅 x 高さ x 厚さ)',
            },
          ],
        },
        {
          name: 'ネットワーク',
          detail: [
            {
              title: '接続性',
              description: '24GHz / Bluetooth 5.0',
            },
          ],
        },
        {
          name: 'インターフェース',
          detail: [
            {
              title: '通知',
              description: 'LED（ステータス）',
            },
          ],
        },
        {
          name: '使用環境と電源',
          detail: [
            {
              title: '動作条件',
              description: '0°C 〜 +40°C、0% 〜 90%',
            },
            {
              title: '電源',
              description: '3.7V - 75mAh / Lithium Polymer',
            },
            {
              title: '定格',
              description: '5V / 40mA',
            },
          ],
        },
      ],
    },
    emlock: {
      title: 'EM Lock',
      spec: [
        {
          name: '外観と素材',
          detail: [
            {
              title: '素材',
              description: 'Aluminum/Gray',
            },
            {
              title: '寸法',
              description: '178mm x 38mm x 25mm (幅 x 高さ x 厚さ)',
            },
          ],
        },
        {
          name: '機能と特徴',
          detail: [
            {
              title: '引張り強度',
              description: '280KG/F',
            },
          ],
        },
        {
          name: '使用環境と電源',
          detail: [
            {
              title: '動作条件',
              description: '-10°C 〜 +55°C',
            },
            {
              title: '動作湿度',
              description: '0 - 90%',
            },
            {
              title: '電源',
              description: '12V/40mA',
            },
          ],
        },
      ],
    },
    deadbolt: {
      title: 'Deadbolt Lock',
      spec: [
        {
          name: '外観と素材',
          detail: [
            {
              title: '素材',
              description: 'Aluminum/Gray',
            },
            {
              title: '寸法',
              description: '203mm x 37mm x 41mm (幅 x 高さ x 厚さ)',
            },
            {
              title: '重量',
              description: '529g',
            },
          ],
        },
        {
          name: '機能と特徴',
          detail: [
            {
              title: '特長',
              description: '内蔵自己開放接点 / ロック監視 / ドア監視可能',
            },
          ],
        },
        {
          name: '使用環境と電源',
          detail: [
            {
              title: '動作条件',
              description: '-10°C 〜 +55°C',
            },
            {
              title: '動作湿度',
              description: '0 - 90%',
            },
            {
              title: '消費電流',
              description: '動作時 900mA / スタンバイ時 350mA',
            },
            {
              title: '電源',
              description: 'DC 12V',
            },
          ],
        },
      ],
    },



    autoDoorSensor: {
      title: 'Automatic Door Sensor',
      spec: [
        {
          name: '外観と素材',
          detail: [
            {
              title: '素材',
              description: 'ABS/White, PC/Translucent Black',
            },
            {
              title: '寸法',
              description: '210.1mm x 60.1mm x 42.2mm (幅 x 高さ x 厚さ)',
            },
          ],
        },
        {
          name: 'プロセッサーとメモリ',
          detail: [
            {
              title: 'プロセッサー',
              description: 'Dual-core 32bit LX6, 240MHz',
            },
            {
              title: 'メモリ',
              description: 'FLASH 8MB、PSRAM 2MB',
            },
          ],
        },
        {
          name: 'ネットワーク',
          detail: [
            {
              title: '接続性',
              description: '24GHz Microwave, SUB 1GHz',
            },
          ],
        },
        {
          name: 'インターフェース',
          detail: [
            {
              title: 'アラーム',
              description: 'Door control, Shock detection',
            },
          ],
        },
        {
          name: '使用環境と電源',
          detail: [
            {
              title: '動作条件',
              description: '0°C 〜 +50°C、0% 〜 90%',
            },
            {
              title: '電源',
              description: 'DC 12V, 24V (Non Polarity)',
            },
          ],
        },
      ],
    },
    twinTracker: {
      link: 'assetTagBle',
      title: 'TwinTracker',
      spec: [
        {
          name: '外観と素材',
          detail: [
            {
              title: '素材',
              description: 'ABS/White, PC/Translucent White, Aluminum/Black',
            },
            {
              title: '寸法',
              description: '95mm x 95mm x 32.5mm (幅 x 高さ x 厚さ)',
            },
          ],
        },
        {
          name: 'プロセッサーとメモリ',
          detail: [
            {
              title: 'プロセッサー',
              description: 'Dual-core 32bit LX6,240MHz',
            },
            {
              title: 'メモリ',
              description: '448KB ROM、520 KB SRAM',
            },
          ],
        },
        {
          name: 'ネットワーク',
          detail: [
            {
              title: 'イーサネット',
              description: '100Base-TX',
            },
            {
              title: 'ワイヤレス',
              description: 'BLE 5.2、Wi-Fi 802.11b/g/n',
            },
          ],
        },
        {
          name: 'インターフェース',
          detail: [
            {
              title: 'シリアル',
              description: 'RS232(USB Console)',
            },
          ],
        },
        {
          name: '使用環境と電源',
          detail: [
            {
              title: '動作条件',
              description: '0°C 〜 +50°C、0% 〜 90%',
            },
            {
              title: '電源',
              description: '3.2W、PoE 802.3af、USB 5V',
            },
          ],
        },
      ],
    },
    twinTrackerPro: {
      link: 'assetTagBle',
      title: 'TwinTracker Pro',
      spec: [
        {
          name: '外観と素材',
          detail: [
            {
              title: '素材',
              description: 'ABS/White, PC/Translucent White, Aluminum/Black',
            },
            {
              title: '寸法',
              description: '95mm x 95mm x 32.5mm (幅 x 高さ x 厚さ)',
            },
          ],
        },
        {
          name: 'プロセッサーとメモリ',
          detail: [
            {
              title: 'プロセッサー',
              description: 'Dual-core 32bit LX6,240MHz',
            },
            {
              title: 'メモリ',
              description: '448KB ROM、520 KB SRAM',
            },
          ],
        },
        {
          name: 'ネットワーク',
          detail: [
            {
              title: 'イーサネット',
              description: '100Base-TX',
            },
            {
              title: 'ワイヤレス',
              description: 'BLE 5.2、Wi-Fi 802.11b/g/n',
            },
          ],
        },
        {
          name: 'インターフェース',
          detail: [
            {
              title: 'シリアル',
              description: 'RS232(USB Console)',
            },
          ],
        },
        {
          name: '使用環境と電源',
          detail: [
            {
              title: '動作条件',
              description: '0°C 〜 +50°C、0% 〜 90%',
            },
            {
              title: '電源',
              description: '3.2W、PoE 802.3af、USB 5V',
            },
          ],
        },
      ],
    },

    assetTagBle: {
      title: 'Asset Tag(BLE)',
      spec: [
        {
          name: '外観と素材',
          detail: [
            {
              title: '素材',
              description: 'ABS/White, ABS/Black, PC/Translucent',
            },
            {
              title: '寸法',
              description: '48.4mm x 48.4mm x 10.7mm (幅 x 高さ x 厚さ)',
            },
            {
              title: '重さ',
              description: '22g',
            },
          ]
        },

        {
          name: 'プロセッサーとメモリ',
          detail: [
            {
              title: 'プロセッサー',
              description: 'Cortex M4 64MHz',
            },
            {
              title: 'メモリ',
              description: '512KB',
            },
          ]
        },

        {
          name: 'ネットワーク',
          detail: [
            {
              title: 'ワイヤレス',
              description: 'BLE 5.2',
            }
          ]
        },

        {
          name: '使用環境と電源',
          detail: [
            {
              title: '動作条件',
              description: '-20°C 〜 +40°C、0% 〜 90%',
            },
            {
              title: '電源',
              description: 'CR2450',
            }
          ]
        },

      ],

    },
    assetTag: {
      title: 'Asset Tag',
      spec: [
        {
          name: '外観及び材質',
          detail: [
            {
              title: '材質',
              description: 'ABS/White, ABS/Gray',
            },
            {
              title: 'サイズ',
              description: '31mm x 31mm x 3.8mm (幅 x 高さ x 奥行)',
            },
          ]
        },

        {
          name: 'プロセッサ及びメモリ',
          detail: [
            {
              title: 'プロセッサ',
              description: 'Cortex M4 64MHz',
            },
            {
              title: 'メモリ',
              description: '64KB',
            },
          ]
        },

        {
          name: 'ネットワーク',
          detail: [
            {
              title: 'ワイヤレス',
              description: 'BLE 5.2',
            }
          ]
        },

        {
          name: '使用環境及び電源',
          detail: [
            {
              title: '動作条件',
              description: '-20°C ~ +40°C, 0% ~ 90%',
            },
            {
              title: '電源',
              description: 'CR2012',
            }
          ]
        },
      ],
    },



    tagUwb: {
      title: 'Tag(UWB)',
      spec: [
        {
          name: '外観と素材',
          detail: [
            {
              title: '素材',
              description: 'ABS/Black',
            },
          ]
        },
        {
          name: 'プロセッサーとメモリ',
          detail: [
            {
              title: 'プロセッサー',
              description: 'Cortex M4 64MHz',
            },
            {
              title: 'メモリ',
              description: '512KB',
            },
          ]
        },

        {
          name: 'ネットワーク',
          detail: [
            {
              title: 'ワイヤレス',
              description: 'UWB',
            },
          ]
        },

        {
          name: '使用環境と電源',
          detail: [
            {
              title: '動作条件',
              description: '-20°C 〜 +40°C、0% 〜 90%',
            },
            {
              title: '電源',
              description: 'USB-C',
            },
          ]
        },

      ],

    },


    safetyBell: {
      title: 'Safety Bell',
      spec: [
        {
          name: '外観と素材',
          detail: [
            {
              title: '素材',
              description: 'ABS/White, ABS/Black, PC/Translucent White',
            },
            {
              title: '寸法',
              description: '64.2mm x 64.2mm x 16.2mm (幅 x 高さ x 厚さ)',
            },
          ]
        },
        {
          name: 'プロセッサーとメモリ',
          detail: [
            {
              title: 'プロセッサー',
              description: 'ARM Cortex M4, 64Mhz',
            },
          ]
        },

        {
          name: 'ネットワーク',
          detail: [
            {
              title: 'ワイヤレス',
              description: 'BLE 5.2, Wi-Fi 802.11b/g/n',
            },
          ]
        },
        {
          name: 'インターフェース',
          detail: [
            {
              title: 'オーディオ',
              description: 'Buzzer',
            },
          ]
        },

        {
          name: '使用環境と電源',
          detail: [
            {
              title: '動作条件',
              description: '-20°C 〜 +40°C、0% 〜 90%',
            },
            {
              title: '電源',
              description: 'AAA x 2',
            },
          ]
        },

      ],

    },



    smartSwitch: {
      title: 'Smart Switch',
      spec: [
        {
          name: '外観と素材',
          detail: [
            {
              title: '素材',
              description: 'ABS/White, ABS/Black, Aluminum/Silver',
            },
            {
              title: '寸法',
              description: '76mm x 120mm x 44.3mm (幅 x 高さ x 厚さ)',
            },
          ]
        },
        {
          name: 'プロセッサーとメモリ',
          detail: [
            {
              title: 'プロセッサー',
              description: 'ARM® Cortex®-M4 32-Bit Processor with FPU, 64MHz',
            },
            {
              title: 'メモリ',
              description: '215 EEMBC CoreMark® Score Running from Flash Memory',
            },
          ]
        },

        {
          name: 'ネットワーク',
          detail: [
            {
              title: 'ワイヤレス',
              description: 'BLE 5.0、Wi-Fi 802.11 b/g/n',
            },
          ]
        },

        {
          name: 'インターフェース',
          detail: [
            {
              title: 'タッチ',
              description: '',
            },
          ]
        },

        {
          name: '使用環境と電源',
          detail: [
            {
              title: '動作条件',
              description: '0°C ~ +60°C',
            },
          ]
        },

      ],

    },




    esl: {
      title: 'ESL',
      spec: [
        {
          name: '外観と素材',
          detail: [
            {
              title: '素材',
              description: 'ABS/White, PC/Transparent',
            },
            {
              title: '寸法',
              description: '76mm x 142mm x 10mm (幅 x 高さ x 厚さ)',
            },
          ]
        },
        {
          name: 'プロセッサーとメモリ',
          detail: [
            {
              title: 'プロセッサー',
              description: 'ARM® Cortex®-M4 32-Bit Processor with FPU, 64MHz',
            },
            {
              title: 'メモリ',
              description: '215 EEMBC CoreMark® Score Running from Flash Memory',
            },
          ]
        },

        {
          name: 'ネットワーク',
          detail: [
            {
              title: 'ワイヤレス',
              description: 'BLE 5.0',
            },
          ]
        },

        {
          name: 'ディスプレイ',
          detail: [
            {
              title: '解像度',
              description: '800 x 600(230dpi)',
            },
            {
              title: 'カラー',
              description: 'Black, White',
            },
            {
              title: 'Max Grayscale',
              description: '16',
            },
          ]
        },

        {
          name: '使用環境と電源',
          detail: [
            {
              title: 'コネクタ',
              description: 'USB-C',
            },
            {
              title: '電源',
              description: '5V, 500mA',
            },
          ]
        },


      ],

    },

    mTag: {
      title: 'Mobile Tag',
      spec: [
        {
          name: '外観と素材',
          detail: [
            {
              title: '素材',
              description: 'ABS/White, PC/Transparent',
            },
            {
              title: '寸法',
              description: '38mm x 50.1mm x 18mm (幅 x 高さ x 厚さ)',
            },
          ]
        },
        {
          name: 'プロセッサーとメモリ',
          detail: [
            {
              title: 'プロセッサー',
              description: 'ARM® Cortex®-M4 64MHz',
            },
            {
              title: 'メモリ',
              description: '512KB',
            },
          ]
        },

        {
          name: 'ネットワーク',
          detail: [
            {
              title: 'ワイヤレス',
              description: 'BLE 5.2',
            },
          ]
        },

        {
          name: 'インターフェース',
          detail: [
            {
              title: 'アラート',
              description: 'LED(White) x 1',
            },
          ]
        },

        {
          name: '使用環境と電源',
          detail: [
            {
              title: 'コネクタ',
              description: 'Lightning, USB-C',
            },
            {
              title: '電源',
              description: 'Lithium Polymer Battery(4.2V, 35mAh)',
            },
          ]
        },


      ],

    },

    mCradle: {
      title: 'Mobile Cradle',
      spec: [
        {
          name: '外観と素材',
          detail: [
            {
              title: '素材',
              description: 'ABS/White, PC/Transparent',
            },
            {
              title: '寸法',
              description: '76mm x 40mm x 25mm (幅 x 高さ x 厚さ)',
            },
          ]
        },
        {
          name: 'プロセッサーとメモリ',
          detail: [
            {
              title: 'プロセッサー',
              description: 'ARM® Cortex®-M4 64MHz',
            },
            {
              title: 'メモリ',
              description: '512KB',
            },
            {
              title: 'センサー',
              description: '3Axis Sensor',
            },
          ]
        },
        {
          name: 'ネットワーク',
          detail: [
            {
              title: 'ワイヤレス',
              description: 'BLE 5.2',
            },
          ]
        },

        {
          name: 'インターフェース',
          detail: [
            {
              title: 'アラート',
              description: 'LED(White) x 1',
            },
          ]
        },

        {
          name: '使用環境と電源',
          detail: [
            {
              title: '電源',
              description: 'DC 5V(USB-C type)',
            },
          ]
        },


      ],

    },


    ptz: {
      title: 'PTZ Camera',
      spec: [
        {
          name: '外観',
          detail: [
            {
              title: '寸法',
              description: '266mm x 266mm x 410mm (幅 x 高さ x 厚さ)',
            },
          ]
        },

        {
          name: 'カメラ',
          detail: [
            {
              title: 'イメージセンサー',
              description: '1/1.8” プログレッシブスキャンCMOS',
            },
            {
              title: 'ズーム',
              description: '光学42倍、デジタル16倍',
            },
            {
              title: '解像度',
              description: '2560 x 1440',
            }
          ]
        },

        {
          name: 'レンズ',
          detail: [
            {
              title: '焦点距離',
              description: '6.0mm - 252mm',
            },
            {
              title: 'ズーム速度',
              description: '4.5秒',
            },
            {
              title: '絞り',
              description: 'F 1.2',
            },
          ]
        },


        {
          name: 'PTZ',
          detail: [
            {
              title: 'パンの移動範囲',
              description: '360°',
            },
            {
              title: 'チルトの移動範囲',
              description: '-20°から90°',
            },
          ]
        },

        {
          name: 'インターフェース',
          detail: [
            {
              title: 'イーサネット',
              description: 'RJ45 10M/100M自己適応イーサネットポート1つ, Hi-PoE',
            },
            {
              title: 'アラーム',
              description: '入力7個、出力2個',
            },
          ]
        },

        {
          name: '使用環境と電源',
          detail: [
            {
              title: '電源',
              description: '24VAC、PoE',
            },
            {
              title: '動作条件',
              description: '-40°C 〜 +70°C、0% 〜 95%',
            },
            {
              title: '防水防塵',
              description: 'IP67',
            },
          ]
        },

      ],

    },



    ip: {
      title: 'IP Camera',
      spec: [
        {
          name: '外観',
          detail: [
            {
              title: '寸法',
              description: '135mm x 135mm x 110mm (幅 x 高さ x 厚さ)',
            },
            {
              title: '重量',
              description: '約800g',
            },
          ]
        },

        {
          name: 'カメラ',
          detail: [
            {
              title: 'イメージセンサー',
              description: '1/2.8" プログレッシブスキャンCMOS',
            },
            {
              title: '解像度',
              description: '1920 × 1080 ',
            }
          ]
        },

        {
          name: 'レンズ',
          detail: [
            {
              title: 'レンズタイプ',
              description: '固定焦点レンズ、2.8mmおよび4mmのオプション ',
            },
            {
              title: '絞り',
              description: 'F 1.4',
            },
          ]
        },
        {
          name: 'インターフェース',
          detail: [
            {
              title: 'イーサネット',
              description: 'RJ45 10 M/100 M自己適応イーサネットポート1つ ',
            },
            {
              title: 'アラーム',
              description: '-S: アラーム入力1つ、アラーム出力1つ（最大. 12 VDC、30 mA） ',
            },
          ]
        },
        {
          name: '使用環境と電源',
          detail: [
            {
              title: '電源',
              description: '12 VDC',
            },
            {
              title: '動作条件',
              description: '-30°C 〜 +60°C、0% 〜 95%',
            },
            {
              title: '防水防塵',
              description: 'IP67',
            },
          ]
        },


      ],

    },

    nvr: {
      title: 'NVR',
      spec: [
        {
          name: 'ビデオとオーディオ',
          detail: [
            {
              title: 'IPビデオ入力',
              description: '8チャンネル',
            },
            {
              title: 'ビデオ出力モード',
              description: 'HDMI/VGA独立出力',
            },
            {
              title: 'HDMI出力',
              description: '4K (3840 × 2160)/30Hz、2K (2560 × 1440)/60Hz、1920 × 1080/60Hz、1600 × 1200/60Hz、1280 × 1024/60Hz、1280 × 720/60Hz ',
            },
            {
              title: 'VGA出力',
              description: '1920 × 1080/60 Hz、1280 × 1024/60 Hz、1280 × 720/60 Hz',
            },
            {
              title: 'オーディオ入力',
              description: '1-チャンネル、RCA (2.0 Vp-p, 1 KΩ) ',
            },
            {
              title: 'オーディオ出力',
              description: '1-ch、RCA (リニア、1 KΩ)',
            }
          ]
        },

        {
          name: 'デコーディング',
          detail: [
            {
              title: 'デコーディング形式',
              description: 'H.265/H.265+/H.264/H.264+',
            },
            {
              title: '録音解像度',
              description: '12 MP/8 MP/6 MP/5 MP/4 MP/3 MP/1080p/UXGA/720p/VGA/4CIF/DCIF/2CIF/CIF/QCIF',
            },
          ]
        },

        {
          name: 'ネットワーク',
          detail: [
            {
              title: 'ネットワークプロトコル',
              description: 'TCP/IP、DHCP、IPv4、IPv6、DNS、DDNS、NTP、RTSP、SADP、SMTP、SNMP、NFS、iSCSI、ISUP、UPnP™、HTTP、HTTPS',
            },
            {
              title: 'ネットワークインターフェース',
              description: '1、RJ-45 10/100/1000 Mbps自己適応イーサネットインターフェース ',
            },
          ]
        },

        {
          name: '使用環境と電源',
          detail: [
            {
              title: '電源',
              description: '12VDV',
            },
            {
              title: '動作条件',
              description: '-10°C 〜 +55°C、10% 〜 90%',
            },
          ]
        },



      ],

    },

    loraGateway: {
      title: 'LoRa ゲートウェイ',
      spec: [
        {
          name: '共通仕様',
          detail: [
            {
              title: '素材',
              description: 'アルミニウム/グレー',
            },
            {
              title: '寸法',
              description: '145mm x 145mm x 42mm（幅 x 高さ x 奥行き）',
            },
            {
              title: '重量',
              description: '750g',
            },
            {
              title: 'RF レンジ',
              description: '920.9MHz〜923.1MHz BW 125KHz',
            },
            {
              title: '通信距離',
              description: 'LOS（視野の中の直線距離）最大6km',
            },
            {
              title: 'アンテナ受信感度',
              description: '-135 dBm',
            },
            {
              title: 'アンテナ送信出力',
              description: '20dBm',
            },
            {
              title: 'セキュリティ',
              description: 'AES-128',
            },
          ],
        },
        {
          name: 'Ethernet ゲートウェイ',
          detail: [
            {
              title: 'Ethernet 仕様',
              description: '10/100 イーサネットコントローラ',
            },
            {
              title: 'IEEE 標準準拠',
              description: '802.3',
            },
            {
              title: '駆動方式',
              description: 'フルデュプレックスおよびハーフデュプレックス',
            },
            {
              title: 'サポートプロトコル',
              description: 'DHCP、DNS、NTP、UDP、TCP、SNMP',
            },
            {
              title: 'ケーブル接続',
              description: 'イーサネット',
            },
          ],
        },
        {
          name: 'Wi-Fi',
          detail: [
            {
              title: 'IEEE 標準準拠',
              description: '802.11b/g/n 2.4GHz',
            },
          ],
        },
        {
          name: 'セルラー LTE',
          detail: [
            {
              title: 'LTE 仕様',
              description: 'LTE CatM1（Telit Me910 C1）',
            },
            {
              title: 'SIM カード仕様',
              description: 'Micro-SIM（3FF）15mm x 12mm x 0.76mm',
            },
          ],
        },
        {
          name: '動作環境および電源',
          detail: [
            {
              title: '動作条件',
              description: '-20°C 〜 60°C',
            },
            {
              title: '電源',
              description: 'DC 8V 〜 24.0V',
            },
            {
              title: '認証',
              description: 'KC、IP67',
            },
          ],
        },
      ],
    },
    loraSensor: {
      title: 'LoRa センサー',
      spec: [
        {
          name: '共通仕様',
          detail: [
            {
              title: '素材',
              description: 'アルミニウム/グレー',
            },
            {
              title: '寸法',
              description: '84mm x 72mm x 31mm（幅 x 高さ x 奥行き）',
            },
            {
              title: '重量',
              description: '300g',
            },
            {
              title: 'RF レンジ',
              description: '920.9MHz〜923.1MHz BW 125KHz',
            },
            {
              title: '通信方法',
              description: 'LoRa',
            },
            {
              title: '通信距離',
              description: 'LOS（視野の中の直線距離）最大6km',
            },
            {
              title: 'セキュリティ',
              description: 'AES-128',
            },
          ],
        },
        {
          name: '動作環境および電源',
          detail: [
            {
              title: '動作条件',
              description: '-20°C 〜 60°C',
            },
            {
              title: '電源',
              description: 'DC 9V 〜 33V<br/>単4形電池 DC 3.6V / 2,500mAh x 2本（並列接続合計 5,000mAh）<br/>単4形電池 DC 1.5V / 2,850mAh x 2本（直列接続合計 2,850mAh）',
            },
            {
              title: '認証',
              description: 'KC、IP67',
            },
          ],
        },
      ],
    },
    airQuality: {
      title: '空気品質モニター',
      spec: [
        {
          name: '共通仕様',
          detail: [
            {
              title: '素材',
              description: 'ABS/ホワイト',
            },
            {
              title: '寸法',
              description: '115mm x 115mm x 30mm（幅 x 高さ x 奥行き）',
            },
            {
              title: '重量',
              description: '215g',
            },
            {
              title: 'ディスプレイ',
              description: '2.0インチ TFT ディスプレイ',
            },
            {
              title: '通信方式',
              description: 'LoRa（オプション）、RS-485',
            },
          ],
        },
        {
          name: '測定範囲',
          detail: [
            {
              title: '微粒子',
              description: 'PM1.0/PM2.5/PM4.0/PM10 : 0 ~ 1,000 µg/m³',
            },
            {
              title: '二酸化炭素（CO2）',
              description: '400 ~ 10,000 ppm',
            },
            {
              title: '揮発性有機化合物（TVOC）',
              description: '0 ~ 6,000 ppb',
            },
            {
              title: '温室効果ガス',
              description: '0 ~ 60,000 ppm',
            },
            {
              title: '温度',
              description: '-40°C 〜 125°C',
            },
            {
              title: '湿度',
              description: '0 ~ 100 %RH',
            },
          ],
        },
        {
          name: '動作環境および電源',
          detail: [
            {
              title: '動作条件',
              description: '-20°C 〜 60°C',
            },
            {
              title: '電源',
              description: 'DC 24V / 0.1A<br/>リチウムポリマー 1500mAh/3.7V',
            },
            {
              title: '認証',
              description: 'KC',
            },
          ],
        },
      ],
    },
    coMeasure: {
      title: '一酸化炭素（CO）モニター',
      spec: [
        {
          name: '共通仕様',
          detail: [
            {
              title: '素材',
              description: 'アルミニウム/グレー',
            },
            {
              title: '寸法',
              description: '93mm x 125mm x 44mm（幅 x 高さ x 奥行き）',
            },
            {
              title: '重量',
              description: '245g',
            },
            {
              title: '通信方法',
              description: 'WiFi（2.4GHz only 802.11 b/g/n）およびRS-485（Modbus RTU）',
            },
          ],
        },
        {
          name: '測定範囲',
          detail: [
            {
              title: '微粒子',
              description: 'PM1.0/PM2.5/PM4.0/PM10 : 0 ~ 1,000 µg/m³',
            },
            {
              title: '一酸化炭素（CO）',
              description: '0 ~ 100 ppm（室温基準）',
            },
            {
              title: '温度',
              description: '-40°C 〜 125°C',
            },
            {
              title: '湿度',
              description: '0 ~ 100 %RH',
            },
          ],
        },
        {
          name: '動作環境および電源',
          detail: [
            {
              title: '動作条件',
              description: '-10°C 〜 40°C',
            },
            {
              title: '電源',
              description: 'DC 24V / 0.1A',
            },
            {
              title: '認証',
              description: 'KC/CE/FCC',
            },
          ],
        },
      ],
    },
    plutoconPro: {
      title: 'Plutocon Pro',
      spec: [
        {
          name: '外観と材料',
          detail: [
            {
              title: '材料',
              description: 'ABS/ホワイト、ABS/グレー、ABS/ブラック、PC/透明',
            },
            {
              title: '寸法',
              description: '幅85.4mm × 高さ85.4mm × 奥行24.5mm',
            },
          ],
        },
        {
          name: 'プロセッサとメモリ',
          detail: [
            {
              title: 'プロセッサ',
              description: 'Cortex M4 64MHz',
            },
            {
              title: 'メモリ',
              description: '512KB、64KB',
            },
          ],
        },
        {
          name: 'ネットワーク',
          detail: [
            {
              title: '接続性',
              description: 'BLE 5.2',
            },
          ],
        },
        {
          name: 'インターフェース',
          detail: [
            {
              title: 'アラーム',
              description: 'RGB LED x 4',
            },
          ],
        },
        {
          name: '動作環境と電力',
          detail: [
            {
              title: '動作条件',
              description: '-10°C ～ +60°C、0% ～ 90%',
            },
            {
              title: '電力',
              description: 'CR2477 1S 4P',
            },
          ],
        },
      ],
    },
    orbroTag: {
      title: 'ORBRO タグ',
      spec: [
        {
          name: '外観と材料',
          detail: [
            {
              title: '材料',
              description: 'ABS/ホワイト、ABS/ブラック、PC/透明',
            },
            {
              title: '寸法',
              description: '幅48.4mm × 高さ48.4mm × 奥行10.7mm',
            },
            {
              title: '重量',
              description: '22g',
            },
          ],
        },
        {
          name: 'プロセッサとメモリ',
          detail: [
            {
              title: 'プロセッサ',
              description: 'Cortex M4 64MHz',
            },
            {
              title: 'メモリ',
              description: '512KB',
            },
          ],
        },
        {
          name: 'ネットワーク',
          detail: [
            {
              title: 'ワイヤレス',
              description: 'BLE 5.2',
            },
          ],
        },
        {
          name: '動作環境と電力',
          detail: [
            {
              title: '動作条件',
              description: '-20°C ～ +40°C、0% ～ 90%',
            },
            {
              title: '電力',
              description: 'CR2450',
            },
          ],
        },
      ],
    },


    idCard: {
      title: 'ID02',
      spec: [
        {
          name: '外観及び材質',
          detail: [
            {
              title: '材質',
              description: 'ABS/White, Aluminum',
            },
            {
              title: 'サイズ',
              description: '51mm x 95.9mm x 7mm(幅 x 高さ x 奥行)',
            },
          ]
        },
        {
          name: 'プロセッサ及びメモリ',
          detail: [
            {
              title: 'プロセッサ',
              description: 'ARM® Cortex®-M4 32-Bit Processor with FPU, 64MHz',
            },
            {
              title: 'メモリ',
              description: '215 EEMBC CoreMark® Score Running from Flash Memory<br/>58μA/MHz Running from Flash Memory<br/>51.6μA/MHz Running from RAM.',
            },
          ]
        },

        {
          name: 'ネットワーク',
          detail: [
            {
              title: 'ワイヤレス',
              description: 'BLE 5.2',
            },
          ]
        },

        {
          name: 'インターフェース',
          detail: [
            {
              title: '通知',
              description: 'LED(RGB) x 1',
            },
            {
              title: 'USB',
              description: 'USB 2.0 Device x 1',
            },
            {
              title: 'ボタン',
              description: 'Slide Switch Button',
            },
          ]
        },

        {
          name: '使用環境及び電源',
          detail: [
            {
              title: '動作条件',
              description: '0°C ~ 50°C',
            },
            {
              title: '電源',
              description: 'Li-polymer (3.7V / 300mA)',
            },
          ]
        },
      ],
    },

    deliveryRobot: {
      title: '配送ロボット',
      spec: [
        {
          name: ' ',
          detail: [
            {
              title: '素材',
              description: 'ABS/白色、PC/半透明白色、アルミニウム/黒色',
            },
            {
              title: '寸法',
              description: '510 x 510 x 1300 (幅 x 奥行 x 高さ)',
            },
            {
              title: '積載容量',
              description: '20L',
            },
            {
              title: '運転環境',
              description: '屋内',
            },
            {
              title: '運転傾斜角',
              description: '+5度/-5度',
            },
            {
              title: '段差高さ',
              description: '1cm/-1cm',
            },
            {
              title: '照度',
              description: '60 ~ 2,000lx (最小 ~ 最大)',
            },
          ]
        },
      ],
    },

    loadingKiosk: {
      title: 'ローディングキオスク',
      spec: [
        {
          name: ' ',
          detail: [
            {
              title: '寸法',
              description: '720 x 800 x 611 (幅 x 奥行 x 高さ)',
            },
            {
              title: '処理容量',
              description: '20L/30L',
            },
            {
              title: 'ディスプレイ',
              description: '10.1インチ マルチタッチディスプレイ',
            },
            {
              title: 'ネットワーク',
              description: 'イーサネット (100Base-TX)/Wi-Fi',
            },
            {
              title: '電源',
              description: '100~240VAC/120W',
            },
          ]
        },
      ],
    },

    cobot: {
      title: 'コボット',
      spec: [
        {
          name: ' ',
          detail: [
            {
              title: '寸法',
              description: '220 x 227 x 1300 (幅 x 奥行 x 高さ)',
            },
            {
              title: '最大到達距離',
              description: '1300mm',
            },
            {
              title: '最大荷重',
              description: '12kg',
            },
            {
              title: '自由度',
              description: '60DoF',
            },
            {
              title: 'ネットワーク',
              description: 'イーサネット (100Base-TX)',
            },
            {
              title: '電源',
              description: '100~240VAC/1kW',
            },
          ]
        },
      ],
    },

    apart: {
      title: 'multi',
      list: [
        { text: "accesscontroller" },
        { text: "serverpc" },
      ]

    },


    assetTracking: {
      title: 'multi',
      list: [
        { text: "twinTracker" },
        { text: "assetTagBle" },
        { text: "serverpcRTLS" },
      ]

    },

    workerTracking: {
      title: 'multi',
      list: [
        { text: "twinTrackerPro" },
        { text: "tagUwb" },
        { text: "serverpcRTLS" },
      ]

    },

    carTracking: {
      title: 'multi',
      list: [
        { text: "ptz" },
        { text: "nvr" },
        { text: "serverpc" },
      ]

    },

    peopleTracking: {
      title: 'multi',
      list: [
        { text: "ip" },
        { text: "nvr" },
        { text: "serverpc" },
      ]

    },

    robotBasic: {
      title: 'multi',
      list: [
        { text: 'deliveryRobot' },
        { text: 'twinTrackerPro' },
        { text: 'tagUwb' },
        { text: 'serverpc' },
      ]
    },

    delivery: {
      title: 'multi',
      list: [
        { text: 'deliveryRobot' },
        { text: 'loadingKiosk' },
        { text: 'cobot' },
        { text: 'twinTrackerPro' },
        { text: 'tagUwb' },
        { text: 'serverpc' },
      ]
    },

    disinfection: {
      title: 'multi',
      list: [
        { text: 'deliveryRobot' },
        { text: 'loadingKiosk' },
        { text: 'cobot' },
        { text: 'twinTrackerPro' },
        { text: 'tagUwb' },
        { text: 'serverpc' },
      ]
    }





  }

}