

export default {

    title: 'ORBRO OS 릴리즈 노트',
    desc: 'ORBRO의 최신 제품 소식을 확인하여 새로운 출시 및 업데이트가 공개되면 가장 먼저 알아보세요.<br/>Platform: ORBRO OS, ORBRO iOS 및 iPadOS, ORBRO AI',

    type: [
        {
            tag: '전체',
        },
        {
            tag: 'ORBRO OS',
        },
        {
            tag: '위치추적',
        },
        {
            tag: '모바일',
        },
        {
            tag: 'AI',
        },
    ],

    notion_id: [

        {
            'title': '오브로 모바일 2.0 업데이트 - Admin v2.0.0',
            'date': '2024-07-15 (월)',
            'tag': ['모바일'],
            'id': '3ac79eabb2c141d48b9d922d20f0dce4',
            'version': '2.0.0',
            'link': '/orbro-app/v2.0.0'
        },

        {
            'title': '모바일 버그 수정 및 기능 추가 (2) v1.1.2',
            'date': '2024-07-08 (월)',
            'tag': ['모바일'],
            'id': '863b98f0f81d4549b77225a825ce0028',
            'version': '1.1.2',
            'link': '/orbro-app/v1.1.2'
        },

        {
            'title': '모바일 버그 수정 및 기능 추가 (1) v1.1.1',
            'date': '2024-07-03 (수)',
            'tag': ['모바일'],
            'id': 'c8812165b0674d5fa97b2a3f780a6e49',
            'version': '1.1.1',
            'link': '/orbro-app/v1.1.1'
        },

        {
            'title': '기기, 알람 메뉴의 데이터 필터기능 v1.6.4',
            'date': '2024-06-20 (목)',
            'tag': ['ORBRO OS'],
            'id': '2de11bf7a848497c93026cb2c1a92a14',
            'version': '1.6.4',
            'link': '/orbro-os/v1.6.4'
        },

        {
            'title': '인터페이스 화면 최적화 v1.6.3',
            'date': '2024-06-20 (목)',
            'tag': ['ORBRO OS'],
            'id': '2905cbce802f4fb2ae3f386458e56e12',
            'version': '1.6.3',
            'link': '/orbro-os/v1.6.3'
        },

        {
            'title': 'Fisheye Camera 추가 v1.6.2',
            'date': '2024-06-20 (목)',
            'tag': ['ORBRO OS'],
            'id': 'be77f3f920e24690a6c3a27bbed2f443',
            'version': '1.1.0',
            'link': '/orbro-os/v1.6.2'
        },
        //
        {
            'title': 'LED 제어기능 개발 v1.1.0',
            'date': '2024-05-30 (목)',
            'tag': ['ORBRO OS', '모바일'],
            'id': '7420e6da4187492db4ae64cefe4271da',
            'version': '1.1.0',
            'link': '/orbro-twintraker-pro/v1.1.0'
        },
        {
            'title': '펌웨어 원격업데이트 기능 추가 v1.0.0',
            'date': '2024-05-23 (목)',
            'tag': ['ORBRO OS', '모바일'],
            'id': '8a40efad46b541d698e412862b213349',
            'version': '1.0.0',
            'link': '/orbro-twintraker-pro/v1.0.0'
        },
        {
            'title': '사물의 위치 인식기능 알파버전 공개',
            'date': '2024-05-20 (월)',
            'tag': ['AI'],
            'id': '4a9e34f886f54d55a89d9783b3ec5302',
            'version': '1.0.3',
            'link': '/orbro-ai/v1.0.3'
        },
        {
            'title': '존 데이터 분석기능 추가',
            'date': '2024-05-16 (목)',
            'tag': ['ORBRO OS'],
            'id': '1a7bc54a89ea4d23b0b40267765a90a1',
            'version': '1.6.1',
            'link': '/orbro-os/v1.6.1'
        },
        {
            'title': '프롬프트, 씬 언더스텐딩 기능 알파버전',
            'date': '2024-05-09 (목)',
            'tag': ['AI'],
            'id': '41cc1fd0d3e24729b624b95637fedcd3',
            'version': '1.0.2',
            'link': '/orbro-ai/v1.0.2'
        },
        {
            'title': '디바이스 메모 기능, 대시보드에 메모 표기',
            'date': '2024-05-07 (화)',
            'tag': ['ORBRO OS'],
            'id': 'a6f26990d7e1480289eb38635cdccba8',
            'version': '1.5.1',
            'link': '/orbro-os/v1.5.1'
        },
        {
            'title': '존에 포함된 오브젝트의 리스트 보기 기능 추가',
            'date': '2024-05-02 (목)',
            'tag': ['ORBRO OS', '위치추적'],
            'id': '4f89418d485a4462880f44d2f3286a2b',
            'version': '1.4.2',
            'link': '/orbro-os/v1.4.2'
        },
        {
            'title': '카메라 전체화면 확대 기능',
            'date': '2024-04-30 (수)',
            'tag': ['ORBRO OS', 'AI'],
            'id': '87e1925b2c854a5cbf4dc728f18a40e1',
            'version': '1.3.4',
            'link': '/orbro-os/v1.3.4'
        },
        {
            'title': '존 생성 및 카운팅 기능',
            'date': '2024-04-30 (수)',
            'tag': ['ORBRO OS', '위치추적'],
            'id': 'cee89848c7e346b9b85a6d453d86d947',
            'version': '1.4.1',
            'link': '/orbro-os/v1.4.1'
        },
        {
            'title': '카메라 위치추적 오브젝트 클릭 기능',
            'date': '2024-04-26 (금)',
            'tag': ['ORBRO OS', 'AI'],
            'id': 'aada3dfd7fe5449b873f13edf129db45',
            'version': '1.3.3',
            'link': '/orbro-os/v1.3.3'
        },
        {
            'title': 'RTSP정보를 이용한 외부카메라 연동 기능',
            'date': '2024-04-25 (목)',
            'tag': ['ORBRO OS', 'AI'],
            'id': 'e0888b9c47ba4374b268956d47fd0ede',
            'version': '1.3.2',
            'link': '/orbro-os/v1.3.2'
        },
        {
            'title': '카메라의 ORBRO AI 기능 추가',
            'date': '2024-04-24 (수)',
            'tag': ['AI'],
            'id': '99d2ed6ef02344f18552fcc99b367934',
            'version': '1.0.1',
            'link': '/orbro-ai/v1.0.1'
        },
        {
            'title': '카메라 위치추적 카테고리 기능 추가',
            'date': '2024-04-23 (화)',
            'tag': ['ORBRO OS', 'AI', '위치추적'],
            'id': 'e0f2793e43014ad3801d8078d5d38201',
            'version': '1.3.1',
            'link': '/orbro-os/v1.3.1'
        },
        {
            'title': '출입권한 확인 기능',
            'date': '2024-04-19 (금)',
            'tag': ['ORBRO OS'],
            'id': '05fb33a1e7f542f9a16962358781ad81',
            'version': '1.2.2',
            'link': '/orbro-os/v1.2.2'
        },
        {
            'title': '태그 정보를 수집한 게이트웨이 표시',
            'date': '2024-04-19 (금)',
            'tag': ['ORBRO OS', '위치추적'],
            'id': '469627083fe148f38d8d6b41a5fcc3b3',
            'version': '1.2.1',
            'link': '/orbro-os/v1.2.1'
        },
        {
            'title': '모바일 디바이스 리스트 상단 포멧 구조 변경',
            'date': '2024-04-19 (금)',
            'tag': ['모바일'],
            'id': '2fbf7a11bd76492bba5693448d1512e9',
            'version': '1.0.3',
            'link': '/orbro-app/v1.0.3'
        },
        {
            'title': '대시보드에 센서 패널 추가',
            'date': '2024-04-18 (목)',
            'tag': ['ORBRO OS'],
            'id': 'bf5f56c0ef984bc2b39f4915f029829e',
            'version': '1.1.2',
            'link': '/orbro-os/v1.1.2'
        },
        {
            'title': 'IoT 센서 모니터링 기능 추가',
            'date': '2024-04-15 (월)',
            'tag': ['ORBRO OS'],
            'id': '0e164e383f704e2388f51e5c9d31eff2',
            'version': '1.1.1',
            'link': '/orbro-os/v1.1.1'
        },
        {
            'title': '오브젝트 호버시 정보 표시',
            'date': '2024-04-11 (목)',
            'tag': ['ORBRO OS', '위치추적'],
            'id': 'f88d584b583549aa85ebed9e90595ce5',
            'version': '1.0.5',
            'link': '/orbro-os/v1.0.5'
        },
        {
            'title': '카메라 전체화면 보기 기능',
            'date': '2024-04-08 (월)',
            'tag': ['AI', '모바일'],
            'id': '9ef16a2a4452486cbe6c0b7d1399b717',
            'version': '1.0.2',
            'link': '/orbro-app/v1.0.2'
        },
        {
            'title': '디바이스 리스트 사용성 개선',
            'date': '2024-04-03 (수)',
            'tag': ['ORBRO OS'],
            'id': '893f1d0669424df0bf2347c5d7044286',
            'version': '1.0.4',
            'link': '/orbro-os/v1.0.4'
        },
        {
            'title': '지도 오브젝트 필터기능 추가',
            'date': '2024-04-01 (월)',
            'tag': ['ORBRO OS'],
            'id': '134e15429574428ba57a21d7e774eec6',
            'version': '1.0.3',
            'link': '/orbro-os/v1.0.3'
        },
        {
            'title': '건물 및 공간 셋팅기능 개선',
            'date': '2024-03-26 (화)',
            'tag': ['ORBRO OS', '위치추적'],
            'id': 'b5d51c990fe64eaa967d258321f2eeca',
            'version': '1.0.2',
            'link': '/orbro-os/v1.0.2'
        },
        {
            'title': '대시보드 신규기능 추가',
            'date': '2024-03-22 (금)',
            'tag': ['ORBRO OS'],
            'id': '27628c1144c04d789f37fe008d873439',
            'version': '1.0.1',
            'link': '/orbro-os/v1.0.1'
        },
        {
            'title': '아이패드/MacOS 버전 릴리즈',
            'date': '2024-03-21 (목)',
            'tag': ['모바일', 'ORBRO OS'],
            'id': 'd7e48841a8864d7285c04ffbcb8e03f5',
            'version': '1.0.1',
            'link': '/orbro-app/v1.0.1'
        }


    ]
}